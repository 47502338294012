body {
  margin: 0;
  padding: 0;
  background-color: #f5f3f4;
  color: #143642;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  text-align: center;
  padding: 20px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #f5f3f4;
}

.chart-container {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.chart-container h1 {
  color: #143642;
  margin-bottom: 10px;
  font-size: 24px;
}

.chart-container h2 {
  color: #143642;
  margin: 15px 0;
  font-size: 20px;
}

.chart-container h3 {
  color: #7d6167;
  margin: 10px 0;
  font-size: 16px;
}

.presidents-summary {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .App {
    padding: 10px;
  }
  
  .chart-container h1 {
    font-size: 20px;
  }
  
  .chart-container h2 {
    font-size: 18px;
  }
}
